import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from "../components/layout"
var showdown  = require('showdown'),
    converter = new showdown.Converter();

export default ({ data }) => {
  const post = data.info
  return (
    <Layout>
      <Helmet>
            <title>{post.title}</title>
      </Helmet>
      <div>
        <h1>{post.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.summary) }} />
        <p>Source : <a href={post.link}>{post.link}</a></p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    info(fields: { slug: { eq: $slug } }) {
      title
      summary
      link
    }
  }
`
